// =========================================== Imports ===========================================
import { useSelector} from 'react-redux'
import {
  selectTasksFiltered,
} from "slices/taskSlice";
import "views/custom.scss"
import KanbanColumn from "views/tasks/KanbanColumn";
import Task from "models/Task";
import KanbanCard from "views/tasks/KanbanCard";
// =====================================================================


export default function Index() {


  let filteredTasks = useSelector(selectTasksFiltered)

  return(
    <div className="page-content-inner">
      <div className="columns is-kanban-wrapper">

        <KanbanColumn
          title={'Approved'} altTitle={'To DO'} tasks={filteredTasks[Task.STATUS.APPROVED]}
          emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
          emptyText={'There are no approved tasks.'}
          KanbanCard={KanbanCard}
        />

        <KanbanColumn
          title={'In Progress'} tasks={filteredTasks[Task.STATUS.IN_PROGRESS]}
          emptyImg={require('assets/img/illustrations/projects/board/progress.svg').default}
          emptyText={'There are no tasks in progress at the moment.'}
          KanbanCard={KanbanCard}
        />

        <KanbanColumn
          title={'Uploaded'} altTitle={'Done'} tasks={filteredTasks[Task.STATUS.UPLOADED]}
          emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
          emptyText={'There are no uploaded tasks at the moment.'}
          KanbanCard={KanbanCard}
        />
      </div>
    </div>
  )
}
