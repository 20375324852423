import FeatherIcon from 'feather-icons-react';
import {useState} from 'react';

export default function CopyButton({textToCopy, hint = 'Copied!'}) {
  const [popUpHint, setPopUpHint] = useState('')

  const handleClick = async (ev) => {
    ev.preventDefault()
    await navigator.clipboard.writeText(textToCopy)
    setPopUpHint(hint)
    setTimeout( () => {
      setPopUpHint('')
    }, 1000)

    return () => {
      setPopUpHint('')
    }
  }

  return(
    <div className="hint--top" data-hint={popUpHint} onClick={handleClick}>
      <FeatherIcon icon={'copy'}/>
    </div>
  )
}
