import {doc, updateDoc} from 'firebase/firestore';
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage';
import {formatDate} from 'helpers';
import {useImageInput} from 'hooks/utils';
import Task from 'models/Task';
import {useFirestore, useStorage} from 'reactfire';
import swal from 'sweetalert';
import ShowTask from "views/tasks/Show";
import {useState} from "react";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import truncate from 'truncate';

export default function KanbanCard({task, title}) {

  const firestore = useFirestore()
  const storage = useStorage()

  const [showIsOpen, setShowIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [handleImageChange, imagePreviewUrl, image, reset] = useImageInput()

  const changeStatus = async (status) => {
    const taskRef = doc(firestore, "tasks", task.id)
    await updateDoc(taskRef, {status: status})
  }

  const handleUpload = async () => {
    setIsLoading(true)
    const taskRef = doc(firestore, "tasks", task.id)
    if(image) {
      const taskImageRef = ref(storage, `tasks/${task.id}.jpg`);
      const uploadTask = await uploadBytesResumable(taskImageRef, image)
      const imageURL = await getDownloadURL(uploadTask.ref)
      await updateDoc(taskRef, {status: Task.STATUS.UPLOADED, imageURL})
    } else {
      await updateDoc(taskRef, {status: Task.STATUS.UPLOADED})
    }
    reset()
    setIsLoading(false)
    await swal('Success', `Task Uploaded Successfully!`, 'success')
    setModalIsOpen(false)
  }

  const handleCancel = () => {
    reset()
    setModalIsOpen(false)
  }

  const createdAt = moment(task.created_at);

  return(
    <div className="kanban-card">
      <div className="card-body">
        <div className="is-flex is-justify-content-space-between" onClick={() => setShowIsOpen(true)}>
          <h6 className="card-title is-size-7">Task # : {task.order}</h6>
          <div>
            <p className="is-size-7">{createdAt.format("DD/MM/YYYY")}</p>
            <p className="is-size-7 has-text-right">{createdAt.format("hh:mm A")}</p>
          </div>
        </div>
        <h4 className="card-title" dir="auto" onClick={() => setShowIsOpen(true)}>{truncate(task.content, 60)}</h4>
        <div className="kanban-card-stats">
          <span onClick={() => setShowIsOpen(true)}>
            <FeatherIcon icon={'clock'} />
            {formatDate(task.creation_date)}
          </span>
          <div className="avatar-stack">
            <div className="is-end">
              <div>
                {title !== 'Approved' &&
                  <div className={`dropdown is-spaced is-dots is-right dropdown-trigger is-pushed-mobile`}
                       onClick={() => changeStatus(title === 'In Progress' ? 'Approved' : 'In Progress')}>
                    <div className="is-trigger" aria-haspopup="true">
                      <FeatherIcon icon={'arrow-left'}/>
                    </div>
                  </div>}
                {title !== 'Uploaded' &&
                  <div className={`dropdown is-spaced is-dots is-right dropdown-trigger is-pushed-mobile`}
                       onClick={title === 'In Progress' ? () => setModalIsOpen(true) : () => changeStatus('In Progress')}>
                    <div className="is-trigger" aria-haspopup="true">
                      <FeatherIcon icon={'arrow-right'}/>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIsOpen && <ShowTask task={task} setIsOpen={setShowIsOpen}/>}
      {modalIsOpen &&
        <div className="modal h-modal is-big is-active">
          <div className="modal-background  h-modal-close"/>
          <div className="modal-content">
            <div className="modal-card">
              <header className="modal-card-head">
                <h3>Upload Task</h3>
                {!isLoading &&
                  <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setModalIsOpen(false)}>
                    <FeatherIcon icon={'x'}/>
                  </button>}
              </header>
              <div className="modal-card-body">
                <div className="inner-content">
                  <img src={imagePreviewUrl || task.imageURL} alt="" />

                  <div className="field is-grouped is-justify-content-end">
                    <div className="control">
                      <div className="file is-primary">
                        <label className="file-label">
                          <input className="file-input" type="file" name="resume" onChange={handleImageChange}/>
                          <span className="file-cta">
                          <span className="file-icon">
                            <FeatherIcon icon={'upload'} />
                          </span>
                          <span className="file-label">
                              Choose a file…
                          </span>
                        </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-card-foot is-end">
                <button className={`button h-button is-rounded h-modal-close ${isLoading && 'is-loading'}`}
                        onClick={handleCancel}>Cancel</button>
                <button className={`button h-button is-primary is-raised is-rounded ${isLoading && 'is-loading'}`}
                        onClick={handleUpload}>Confirm</button>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}
