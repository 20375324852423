// noinspection JSCheckFunctionSignatures

import taskConverter from 'converters/taskConverter';

// noinspection ES6CheckImport
import {collection, query, where, onSnapshot, orderBy} from "firebase/firestore";
import { createSlice, createSelector } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";
import Task from "models/Task";


// =========================== Slice ===========================
export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    tasks: [],
    favoriteTasks: []
  },
  reducers: {
    set: (state, action) => {
      state.tasks = action.payload
    },
    remove: (state, action) => {
      state.tasks.filter((task) => task.content !== action.payload)
    }
  },
})

// ============================= Actions =============================
export const { set, remove } = taskSlice.actions

export const fetchTasks = assigneeLink => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter),
    where('assignees_ids', 'array-contains', assigneeLink), orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(tasks))
  })
};

export const fetchAllTasks = () => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter), orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      tasks.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(tasks))
  })
}

// ============================ Selectors ============================
export const selectTasks = state => state.task.tasks;
export const selectFavoriteTasks = state => state.task.favoriteTasks;

export const selectTasksFiltered = createSelector(selectTasks, (tasks) => {
  const filtered = initializeFiltered()
  filtered['All'] = tasks;
  tasks.forEach(task => {
    filtered[task.status].push(task)
  })
  return filtered;
})


// =========================== Default Export =========================
export default taskSlice.reducer

// =========================== Helper Methods =========================
function initializeFiltered() {
  return {
    All: [], [Task.STATUS.PENDING]: [], [Task.STATUS.MISSING_INFORMATION]: [], [Task.STATUS.REJECTED]: [],
    [Task.STATUS.APPROVED]: [], [Task.STATUS.IN_PROGRESS]: [], [Task.STATUS.UPLOADED]: [], [Task.STATUS.DONE]: []
  }
}
