import CopyButton from 'components/CopyButton';
import Gallery from 'components/Gallery';
import FeatherIcon from "feather-icons-react";
import {formatDate} from 'helpers';
import {useButtonListener} from 'hooks/utils';
import {useEffect, useState} from 'react';
import {doc, getDoc} from "firebase/firestore";
import {useFirestore} from 'reactfire';


export default function Show({ task, setIsOpen}) {

  const [client, setClient] = useState({})
  useButtonListener(() => {setIsOpen(false)}, 27)
  const firestore = useFirestore()

  useEffect(() => {
    getDoc(doc(firestore, 'users', task.client_id))
      .then( (clientDocSnap) => {
        setClient(clientDocSnap.data())
      })
  }, [])

  return(
    <div className="modal h-modal is-active is-big">
      <div className="modal-background  h-modal-close"/>
      <div className="modal-content">
        <div className="modal-card">
          <header className="modal-card-head">
            <h3>Task overview</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={() => setIsOpen(false)}>
              <FeatherIcon icon={'x'} />
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <table className="table is-hoverable is-fullwidth">
                <tbody>
                <tr>
                  <td>Client Name</td>
                  <td className='is-end' colSpan={2}>{client.displayName}</td>
                </tr>
                <tr>
                  <td>ID</td>
                  <td className='is-end' colSpan={2}>{task.order}</td>
                </tr>
                <tr>
                  <td>Creation Date</td>
                  <td className='is-end' colSpan={2}>{formatDate(task.creation_date)}</td>
                </tr>
                <tr>
                  <td>Title</td>
                  <td className='is-end' dir="auto"><p className="content">{task.title}</p></td>
                  <td>
                    <CopyButton textToCopy={task.title} />
                  </td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td className='is-end' dir="auto"><p className="content">{task.content}</p></td>
                  <td>
                    <CopyButton textToCopy={task.content} />
                  </td>
                </tr>
                <tr>
                  <td>Design Sizes</td>
                  <td className='is-end' dir="auto" colSpan={2}>
                    <ul>
                      {task.design_sizes.map((size, index) => <li key={index}>- {size}</li>)}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td className='is-end' dir="auto"><p className="content">{task.notes}</p></td>
                  <td>
                    <CopyButton textToCopy={task.notes} />
                  </td>
                </tr>
                <tr>
                  <td>Folder Name</td>
                  <td className='is-end'><p className="content">{task.folder_name}</p></td>
                  <td>
                    <CopyButton textToCopy={task.folder_name} />
                  </td>
                </tr>
                <tr>
                  <td>Admin Notes</td>
                  <td className='is-end' dir="auto"><p className="content">{task.admin_notes}</p></td>
                  <td>
                    <CopyButton textToCopy={task.admin_notes} />
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td className='is-end' colSpan={2}>{task.status}</td>
                </tr>
                {task.imageURL ?
                  <>
                    <tr>
                      <td colSpan={3} className="align-text-center">Preview</td>
                    </tr>
                    <tr>
                      <td colSpan={3}><Gallery images={[task.imageURL]}/></td>
                    </tr>
                  </> : <tr />}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a href={'#'} className="button h-button is-primary is-raised is-rounded" onClick={() => setIsOpen(false)}>
              Confirm
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
