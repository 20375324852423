// Member Views
import TasksIndex from 'views/tasks/Index';
import EditProfile from 'views/EditProfile';

// Auth Views
import Login from 'views/auth/Login';

import constants from "constants.js"


const routes = [
  // ========================================= Member routes =========================================
  {
    path: '/',
    name: 'Tasks',
    icon: 'list',
    component: TasksIndex,
    layout: constants.MEMBER,
  },
  {
    path: '/profile/edit',
    name: 'Edit Profile',
    icon: 'user',
    component: EditProfile,
    layout: constants.MEMBER,
    hide: true
  },
  // ========================================= Auth routes =========================================
  {
    path: '/login',
    name: 'Login',
    icon: 'login',
    component: Login,
    layout: constants.AUTH,
  },
];
export default routes;
