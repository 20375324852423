import { configureStore } from '@reduxjs/toolkit'
import taskReducer from "slices/taskSlice";
import notificationReducer from 'slices/notificationSlice';

export default configureStore({
  reducer: {
    task: taskReducer,
    notification: notificationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['task/set','task/setFavorite', 'task/filterByMonth', 'task/filterByYear',
          'task/filterByStatus', 'notification/set'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
})
